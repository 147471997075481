<template>
  <VContainer class="fill-height">
    <VRow
      v-if="loading"
      class="fill-height"
      align="center"
    >
      <VCol align="center">
        <VProgressCircular
          :size="70"
          indeterminate
          color="primary"
        />
      </VCol>
    </VRow>
    <VRow v-else>
      <VCol
        md="6"
        sm="6"
        xs="12"
        offset-md="3"
        offset-sm="3"
        class=""
        data-test=""
      >
        <h1 class="pb-4 tt-text-headline-1">
          Добро пожаловать
        </h1>
        <p
          v-if="error.message"
          class="error--text mb-12"
          data-test="text-error-message"
        >
          {{ error.message }}
        </p>
        <TTBtn
          to="/login"
          large
          depressed
          data-test="button-go-back"
        >
          На форму авторизации
        </TTBtn>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
// TODO: Переделать верстку смотри ./SingIn.vue

import * as snamiApi from '@/services/backend/snami';
import { authToken } from '@/services';
import { mapActions } from 'vuex';

export default {
  name: 'SignInTwoFactor',
  data() {
    return {
      error: { message: '' },
      loading: true,
    };
  },
  created() {
    authToken.remove();
    const { params } = this.$route;
    snamiApi.customerLoginTwoFactor({
      login: params.login,
      key: params.key,
    }).then((r) => {
      const { data } = r.data;
      const { token } = data;
      authToken.set(token);
      this.setShowUsedeskHint(true);
      return this.$router.push('/');
    }).catch((err) => {
      this.error = err.response.data.error;
    }).finally(() => {
      this.loading = false;
    });
  },
  methods: {
    ...mapActions(['setShowUsedeskHint']),
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
