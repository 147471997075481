<template>
  <VContainer class="fill-height">
    <VRow justify="center">
      <VCol
        md="6"
        sm="6"
        xs="12"
        class=""
        data-test=""
      >
        <VForm
          v-if="!twoFactor && !restore"
          ref="form"
          lazy-validation
        >
          <VRow>
            <VCol>
              <h1 class="tt-text-headline-1">
                Добро пожаловать
              </h1>
              <span
                v-if="error.message"
                class="error--text"
                data-test="text-error-message"
              >{{ error.message }}</span>
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <TTTextField
                v-model.trim="login"
                large
                label="Введите e-mail"
                required
                :error-messages="emailError"
                data-test="input-login"
                @blur="checkEmail"
                @input="emailError = []"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <TTTextField
                v-model.trim="password"
                large
                label="Введите пароль"
                type="password"
                required
                :rules="requiredRules"
                data-test="input-password"
                @input="error = { message : ''}"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <div class="d-flex align-center justify-end">
                <TTBtn
                  color="tt-ghost--text"
                  large
                  :disabled="loading"
                  data-test="button-not-remember-password"
                  @click="restore=true"
                >
                  Не помню пароль
                </TTBtn>
                <TTBtn
                  large
                  depressed
                  :loading="loading"
                  class="ml-2"
                  data-test="button-to-come"
                  @click="signIn"
                >
                  Войти
                </TTBtn>
              </div>
            </VCol>
          </VRow>
        </VForm>
        <VForm
          v-show="restore"
          ref="restoreForm"
          lazy-validation
        >
          <VRow>
            <VCol>
              <h1 class="pb-4 tt-text-headline-1">
                Сброс пароля
              </h1>
              <span
                v-if="error.message"
                class="error--text"
                data-test="text-error-message"
              >{{ error.message }}</span>
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <template v-if="!restoreStatus">
                <TTTextField
                  v-model.trim="email"
                  large
                  label="Введите e-mail"
                  required
                  data-test="input-email"
                  @input="error = { message : ''}"
                />
              </template>
              <div v-else>
                Новый пароль отправлен Вам на почту!
              </div>
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <div class="d-flex justify-end">
                <TTBtn
                  large
                  color="tt-ghost--text"
                  data-test="button-go-back"
                  @click="goBack"
                >
                  Назад
                </TTBtn>
                <TTBtn
                  v-if="!restoreStatus"
                  large
                  depressed
                  class="ml-2"
                  data-test="button-restore"
                  @click="handleRestore"
                >
                  Отправить
                </TTBtn>
              </div>
            </VCol>
          </VRow>
        </VForm>

        <div v-if="twoFactor">
          <h1 class="pb-4 tt-text-headline-1">
            Добро пожаловать
          </h1>
          <h2>Двухфакторная авторизация</h2>
          <p>Администратор вашей компании включил двух-факторную авторизацию</p>
          <p data-test="text-login">
            Вам на почту {{ login }} отправлена ссылка для входа в административный интерфейс
          </p>
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
// TODO: ДОБАВИТЬ НОРМАЛЬНУЮ ВАЛИДАЦИЮ

import * as snamiApi from '@/services/backend/snami';
import { authToken, pageLocationToken } from '@/services';
import { mapActions } from 'vuex';

// TODO: email regex сложнее чем тут
// eslint-disable-next-line no-useless-escape,max-len,vue/max-len
const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export default {
  name: 'SignIn',
  inject: ['Names'],
  data() {
    return {
      href: 'https://snami-test.talenttechlab.org/login',
      login: '',
      loading: false,
      password: '',
      emailError: [],
      restore: false,
      restoreStatus: false,
      email: '',
      twoFactor: false,
      error: { message: '' },
      requiredRules: [
        (v) => !!v || 'Обязательное поле',
      ],
    };
  },
  computed: {
    validEmail() {
      return !!this.login && emailRegex.test(this.login);
    },
  },
  created() {
    this.href = document.location.href;
    authToken.remove();
    pageLocationToken.remove();
  },
  methods: {
    ...mapActions(['setShowUsedeskHint', 'initStoreAction']),
    goBack() {
      this.restore = false;
      this.restoreStatus = false;
      this.error = { message: '' };
    },
    checkEmail() {
      if (!this.login) {
        this.emailError.push('Необходимо заполнить');
      } else if (!this.validEmail) {
        this.emailError.push('Некорректный e-mail');
      }
    },
    handleRestore() {
      if (this.$refs.restoreForm.validate()) {
        const { email } = this;
        snamiApi.resetPassword(email).then(() => {
          this.restoreStatus = true;
        }).catch((err) => {
          this.error = err.response.data.error;
        });
      }
    },
    async signIn() {
      const isValid = this.$refs.form.validate();
      try {
        if (isValid) {
          this.loading = true;
          const { login, password } = this;
          const key = `${this.href}/${login}/{key}`;
          const { data: { data } } = await snamiApi.customerLogin({ login, password, two_factor_endpoint: key });
          if (!data.two_factor) {
            const { token } = data.token_data;
            authToken.set(token);
            this.setShowUsedeskHint(true);
            await this.initStoreAction();
            this.$router.push({ name: this.Names.R_HOME });
          } else {
            this.twoFactor = true;
          }
        }
      } catch (e) {
        if (e.response.status === 403) {
          this.error = e.response.data.error;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
